
.list-move,
.list-enter-active,
.list-leave-active,
.cards-move,
.cards-enter-active,
.cards-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(300px);
}

.cards-enter-from,
.cards-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}

.cards-leave-active,
.list-leave-active {
  position: absolute;
}
