
.vfm--overlay.d-popup-simple__overlay {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(20px);
}
.vfm--overlay.d-popup-simple__overlay._type-single {
  background: #f2f3f7;
  backdrop-filter: none;
}

