
:deep(.search-filter) {
  background-color: #fff;
  height: 100%;
  overflow-y: auto;
  padding-top: 72px;
}

:deep(.vfm__content.search-filter) {
  width: 100%
}

:deep(.search-filter .d-popup__close) {
  top: 12px;
  right: 24px;
}

@media (max-width: 767px) {
  :deep(.search-filter) {
    padding-top: 60px;
  }

  :deep(.search-filter .d-popup__close) {
    width: 32px !important;
    height: 32px;
    top: 14px;
    right: 12px;
  }
}
