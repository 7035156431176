
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.d-popup__close) {
  top: 40px;
  right: 40px;
}

.black-friday__popup-picture {
  object-fit: cover;
  max-height: 800px;
}

@media screen and (max-width: 1919px) {
  .black-friday__popup-picture {
    max-height: 600px
  }

  :deep(.d-popup__close) {
    top: 32px;
    right: 32px;
  }
}

@media screen and (max-width: 1279px) {
  .black-friday__popup-picture {
    max-height: 538px
  }
}

@media screen and (max-width: 767px) {
  .black-friday__popup-picture {
    max-height: 224px
  }

  :deep(.d-popup__close) {
    top: 12px;
    right: 12px;
    width: 48px !important;
    height: 48px !important;
  }

  :deep(.d-overlay) {
    display: block !important;
  }
}
