
.d-tabs__content {
  position: relative;
}

.d-flat-plan__compass {
  position: absolute;
  object-fit: none;
  width: unset;
  height: unset;
  bottom: 24px;
  left: 32px;
}

